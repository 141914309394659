import { makeStyles, useTheme, useMediaQuery, Box } from '@material-ui/core';
import clsx from 'clsx';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import Loading from './Loading.component';
import TextField from './TextField.component';

export const MESSAGE_EXPIRED_CODE = 'El código venció, podés enviar uno nuevo.';

export const FIELDS_INITIAL_VALUE = {
  character1: '',
  character2: '',
  character3: '',
  character4: '',
};

export const KEY_CODE_BACKSPACE = 8;

const useStyle = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '25rem',
    maxHeight: '100vh',
    [theme.breakpoints.down('sm')]: {
      padding: `0 ${theme.space.horizontal.mobile}`,
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: '0.6875rem',
    marginLeft: '1.6rem',
    marginTop: '0.25rem',
  },
  loading: {
    position: 'absolute',
    top: '0',
  },
  borderFocus: {
    '& fieldset': {
      border: `1px solid ${theme.palette.primary.main2} !important`,
    },
  },
  borderWithExpiredCode: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.grayScale.g200,
      },
      '&:hover': {
        '& fieldset': {
          borderColor: `${theme.palette.grayScale.g200} !important`,
        },
      },
    },
  },
  textInput: {
    marginTop: 0,
    marginLeft: '0.625rem',
    width: '4.2188rem',
    '& input': {
      height: '2rem',
      textAlign: 'center',
      fontWeight: theme.typography.weight.semibolder,
    },
    '& label': {
      position: 'absolute',
      top: '50%',
      left: ' 1.25rem',
      transform: 'translate(0, -50%)',
    },
    '& .MuiOutlinedInput-root': {
      borderColor: theme.palette.grayScale.g200,
      '&:hover': {
        '& fieldset': {
          borderColor: theme.palette.grayScale.g400,
        },
      },
      '&.Mui-error': {
        '&:hover': {
          '& fieldset': {
            borderColor: theme.palette.error.main,
          },
        },
      },
      '&.Mui-error.Mui-focused': {
        '& fieldset': {
          border: `1px solid ${theme.palette.error.main}`,
        },
      },
    },
  },
}));

const ErrorMessage = ({ text }) => {
  const classes = useStyle();
  return <Box className={classes.errorMessage}>{text}</Box>;
};

const CodeInput = ( {
  fields = FIELDS_INITIAL_VALUE,
  setFields = () => {},
  validateFields = () => {},
  errorMessage = '',
  loading = false,
  length = 4,
  hasFocus = false,
}) => {

  const [focus, setFocus] = useState(false);
  const [styleFocus, setStyleFocus] = useState(false);
  const classes = useStyle();

  const codeLengthArray = Array.from({ length });
  const [inputRefs, pushInputRef] = useListRef();

  const fieldsKeys = Object.keys(fields);
  const fieldsValues = Object.values(fields);

  const onFocus = () => setStyleFocus(true);

  const onBlur = () => setStyleFocus(false);


  const handleTextFieldChange = (key, e, value) => {
    if(e){
      const elementPosition = inputRefs.findIndex(element => element === e.target);
      if(elementPosition < inputRefs.length - 1) {
        inputRefs[elementPosition + 1].focus();
      }
    }

    const newFields = { ...fields };
    newFields[key] = value;
    setFields(newFields);
    validateFields(newFields);
  };


  const handleKeyDown = (key, e) => {
    const elementPosition = inputRefs.findIndex(element => element === e.target);
    if (e.keyCode === KEY_CODE_BACKSPACE) {
      if (key === fieldsKeys[0]) {
        handleTextFieldChange(key, null, '');
        return;
      }
      inputRefs[elementPosition - 1].focus();
      handleTextFieldChange(key, null,'');
    } else if (e.target.value  && (elementPosition < inputRefs.length - 1)) {
      inputRefs[elementPosition + 1].focus();
    }
  };

  //Setea el puntero al primer input
  useEffect(() => {
    if(focus){
      inputRefs[0].focus();
    }
  }, [focus, inputRefs]);

  const alReadyRendered = useRef(null);

  //Invoca al use Effect anterior
  useEffect(() => {
    //Valida que al iniciar el componente no se llame
    if(alReadyRendered.current){
      setFocus(hasFocus);
    }
  }, [hasFocus]);

  //Setea el focus al ingresar al componente
  useEffect(() => {
    alReadyRendered.current = true;
    setFocus(true);
  }, []);


  return (
    <>
      {codeLengthArray.map((code,index) =>{
        return(
          <>
            <SingleCodeInput
              key={fieldsKeys[index]}
              inputRef={pushInputRef}
              value={fieldsValues[index]}
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={(e) => e.target.value && handleTextFieldChange(fieldsKeys[index], e, e.target.value)}
              onKeyDown={(e) =>{ handleKeyDown(fieldsKeys[index], e); }}
              focus={styleFocus}
              errorMessage={errorMessage}
            />
          </>
        );
      })}
      {loading && <Loading className={classes.loading} />}
      {errorMessage && <ErrorMessage text={errorMessage} />}
    </>

  );
};

export default CodeInput;

function useListRef() {
  const alreadyRendered = useRef(null);
  const inputRefs = useRef([]);
  const pushInputRef = (element) => {
    if(!alreadyRendered.current) inputRefs.current.push(element);
  };
  useEffect(() => {
    if(!alreadyRendered.current) alreadyRendered.current = true;
  }, []);
  return [inputRefs.current, pushInputRef];
}

const SingleCodeInput = forwardRef(({ errorMessage, onChange, focus, ...props }, ref) => {
  const classes = useStyle();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleOnChange = (e) => {
    const value = e.target.value;
    // si es un número y es positivo
    if (!isNaN(value) && +value >= 0) {
      onChange(e);
    }
  };

  return (
    <TextField
      className={clsx(classes.textInput, {
        [classes.borderFocus]: (isMobile || focus) && !errorMessage,
        [classes.borderWithExpiredCode]: errorMessage === MESSAGE_EXPIRED_CODE,
      })}
      variant="outlined"
      inputProps={{ maxLength: 1 }}
      disabled={errorMessage === MESSAGE_EXPIRED_CODE}
      error={errorMessage}
      onChange={handleOnChange}
      ref = {ref}
      {...props}
    />
  );
});
