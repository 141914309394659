import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import Button from '../common/button.component';
import { default as SuccessIcon } from '../../assets/icons/success_icon.svg';
import Text from '../common/text.component';
import { goToIngresar } from '../../api/navigation'


const LOGIN_TEXT_BTN = 'Ingresar a mi cuenta'
const SUCCESS_TITLE = '¡Listo!'
const SUCCES_SUBTITLE = 'Ya podés usar tu nueva contraseña para ingresar.'


const useStyle = makeStyles((theme) => ({
    button: {
        marginTop: '3.5rem',
        width: '100%', 
        fontSize: '16px',
      },
    title:{
        marginBottom: '10px',
    },
    boxFlex: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    succesIcon:{
        marginBottom: '55px',
    },
    }));

const NewPassword = () => {
    const classes = useStyle();
    return(
        <>
      
    <Box className={`${classes.container} ${classes.boxFlex}`}>
      <Box className={classes.succesIcon}>
        <SuccessIcon />
      </Box>
      <Text className={classes.title} large semibolder>
        {SUCCESS_TITLE}        
      </Text>
      <Text className={classes.title} little g600 center>
       {SUCCES_SUBTITLE}
      </Text>
      <Button
        primary
        className={classes.button}
        type="submit"
        onClick={goToIngresar}>
        {LOGIN_TEXT_BTN}
      </Button>
      </Box>
    </>

    )
}

export default NewPassword;