import { useMutation, useQuery } from '@apollo/react-hooks';
import React from 'react';
import { PasswordActivationContainer } from '../../components/recuperar/PasswordActivationContainer';
import RecuperarContrasenia from '../../components/recuperar/RecuperarContrasenia.component';
import SomethingHappened from '../../components/recuperar/SomethingHappened.component';
import { VERIFY_EMAIL } from '../../queries/verifyEmail';
import ChangePasswordForm from '../../components/recuperar/ChangePasswordForm';
import { SEND_PASSWORD_RECOVERY_CODE } from '../../mutations/sendPasswordRecoveryCode';


export default function RecuperarContraseniaContainer({
  showValidation,
  setShowValidation,
  emailValidationError,
  showErrorView,
  hideErrorView,
  fields,
  setFields,
}) {

  // Se inicializa con skip para que solo se haga la request con refetch
  const { refetch: verifyEmail } = useQuery(VERIFY_EMAIL, { skip: true });

  const [sendPasswordRecoveryCode] = useMutation(SEND_PASSWORD_RECOVERY_CODE);
  const [ showChangePassword, setShowChangePassword ] = React.useState({
    code:'',
    show:false
  });

  const checkIfEmailExists = () => {
    return verifyEmail({ email: fields.email });
  };

  if (showChangePassword.show) {
    return <ChangePasswordForm email={fields?.email} code = {showChangePassword?.code} />;
  };

  if(emailValidationError) {
    return <SomethingHappened email={fields.email} hideErrorView={hideErrorView}/>;
  }

  if(showValidation){
    return <PasswordActivationContainer email={fields.email} onAcceptCode={setShowChangePassword}/>;
  }

  return (
    <RecuperarContrasenia
      fields={fields}
      setFields={setFields}
      checkIfEmailExists={checkIfEmailExists}
      setShowValidation={setShowValidation}
      showErrorView={showErrorView}
      sendPasswordRecoveryCode={sendPasswordRecoveryCode}
    />
  );
};
