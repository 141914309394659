
export const MESSAGE_EXPIRED_CODE = 'El código venció, podés enviar uno nuevo.';
export const MESSAGE_INVALID_CODE = 'El código no es válido. Intentá de nuevo.';

export const FIELDS_INITIAL_VALUE = {
  character1: '',
  character2: '',
  character3: '',
  character4: '',
};

export const KEY_CODE_BACKSPACE = 8;
