
export const isValidPass = (text = '') => {
  const passControl = {
    charUp: (text.match(/[a-z]/g) || []).length,
    charLow: (text.match(/[A-Z]/g) || []).length,
    charNumber: (text.match(/[0-9]/g) || []).length,
    textLength: text.length
  };
  return passControl.textLength > 7
          && passControl.charUp > 0
          && passControl.charLow > 0
          && passControl.charNumber > 0;
};
