import React, { useState } from 'react';
import Layout from '../components/layouts/OnboardingLayout.component';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import RecuperarContraseniaContainer from '../containers/recuperar/RecuperarContrasenia.container';
import Header from '../components/header/Header.component';
import { navigate } from '../api/navigation';
import { navigationRoutes } from '../api/navigationRoutes.enum';

const useStyles = makeStyles((theme) => ({
  link: {
    height: '1rem',
    marginRight: 'auto',
    paddingLeft: '0.8rem',
    paddingTop: '0.8rem',
  },
  icon: {
    height: '1rem',
    marginBottom: '2px',
    marginRight: '-2px',
  },
  recComponent: {
    paddingTop: '4.625rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '1.5rem',
      marginRight: '1.5rem',
    },
  },
  header:{
    '& > .MuiGrid-root > .MuiPaper-root > .MuiToolbar-regular':{
      [theme.breakpoints.down('sm')]: {
        minHeight:'4rem',
      }
    }
  }
}));

const PAGE_TITLE = 'Recuperar clave - Login - Carmuv ';
const META_DESCRIPTION = 'Recuperar clave.';
const LABEL_LOGIN = 'Ingresar a mi cuenta';
const FIELDS_INITIAL_VALUE = {
  email: '',
};

const RecuperarPage = ({ location, pageContext }) => {
  const classes = useStyles();
  const [showValidation, setShowValidation] =  useState(false);
  const [emailValidationError, setEmailValidationError] = useState(null);
  const [fields, setFields] = useState(FIELDS_INITIAL_VALUE);

  const showErrorView = () => {
    setEmailValidationError(true);
  };

  const hideErrorView = () => {
    setFields(prevFields => ({ ...prevFields, email: '' }));
    setEmailValidationError(() => false);
  };

  const onGoBack = () => {
    if(emailValidationError) {
      return hideErrorView();
    }

    return navigate(navigationRoutes.INGRESAR);
  };

  return (
    <Layout title={PAGE_TITLE} description={META_DESCRIPTION}>
      <Box className={classes.header}>
        <Header
          menuLinks={[]}
          isLoggedIn={false}
          showMobileBackIcon
          styleButtonBlack
          showMenuButton={!showValidation}
          onGoBack={onGoBack}
          loginText={showValidation ? ' ' : LABEL_LOGIN}
        />
      </Box>
      <Box className={classes.recComponent}>
        <RecuperarContraseniaContainer
          showValidation = {showValidation}
          setShowValidation={setShowValidation}
          showErrorView={showErrorView}
          hideErrorView={hideErrorView}
          emailValidationError={emailValidationError}
          fields={fields}
          setFields={setFields}
        />
      </Box>
    </Layout>
  );
};

export default RecuperarPage;
