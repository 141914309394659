import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import Button from '../common/button.component';
import Text from '../common/text.component';
import PasswordTextField from '../common/PasswordTextField.component';
import { isValidPass } from './isValidPass';
import LoadingModal from '../../components/common/LoadingModal.component';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_PASSWORD } from '../../mutations';
import NewPassword from './NewPassword';
import clsx from 'clsx';

const useStyle = makeStyles(
  (theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& .MuiFormControl-root':{
        marginTop:'1.875rem'
      },
    },
    textInput: {
      width:'400px',
      lineHeight:'21px',
      marginBottom: '2rem',
      [theme.breakpoints.down('md')]:{
        width:'350px',
        marginBottom: '2.8rem',
      },
      font:'14px',
      '& .MuiInputBase-input':{
        height:'2.125rem',
      },
      '& .MuiInputBase-root': {
        paddingRight: '0.5rem',
      },
      '& .MuiFormLabel-root': {
        color: `${theme.palette.grayScale.g700} !important`,
      },
      '& .MuiInputLabel-outlined': {
        transform: 'translate(0.875rem, 1.05rem) scale(1) !important',
        paddingLeft: '.6rem',
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(.6rem, -1rem) !important',
        fontWeight: '400 !important',
        fontSize: '12px !important',
        color: '#717171 !important',
      },
      '& .MuiFormHelperText-root': {
        marginLeft: '1rem !important',
        position: 'absolute',
        transform: 'translate(.4rem, 3rem) !important',
        fontWeight: '400 !important',
        fontSize: '11px !important',
        lineHeight: '16px !important',
        width: '380px',
        color: '#5C5C5C !important',
        [theme.breakpoints.down('md')]:{
          width:'350px',
        },
        '&.Mui-error': {
          color: '#C53814 !important',
        }
      },
    },
    inputRepeatPass: {
      '& .MuiFormHelperText-root': {
        color: '#C53814 !important',
      }
    },
    title:{
      color: '#262626',
      fontWeight: '600',
      fontSize:'1.25rem',
      lineHeight:'1.875rem',
      marginBottom: '1.2rem',
    },
    button: {
      marginTop: theme.spacing(3),
      width:'400px',
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.01em',
      [theme.breakpoints.down('md')]:{
        width: '350px',
        height: '48px',
      },
    },
    sectionLeyendSecondary: {
      width: '60%',
      [theme.breakpoints.down('md')]:{
        width: '90%',
      },
      position: 'absolute',
      height: 'auto',
      padding: '0 10px 0 10px',
    }
  })
);


const ChangePasswordForm = ({ email, code }) => {
  const [ newPass, setNewPass ] = React.useState('');
  const [ repeatPass, setRepeatPass ] = React.useState('');
  const [ isErrorPass, setIsErrorPass ] = React.useState(false);
  const [ isErrorRepeatPass, setIsErrorRepeatPass ] = React.useState(false);
  const [ showRepeatMessage, setShowRepeatMessage ] = React.useState(false);
  const [ showErrorModal, setShowErrorModal ] = React.useState(false);
  const [ showNewPassPage, setShowNewPassPage] = React.useState(false)
  const classes = useStyle();

  const [updatePassword, {
    data: passResponse,
    loading: isChangingPass,
    error: errorChangingPass
  }] = useMutation(UPDATE_PASSWORD);


  React.useEffect(() => {
    if (!isChangingPass && passResponse?.updatePassword?.success)
      setShowNewPassPage(true)
    else if (!isChangingPass && errorChangingPass)
      setShowErrorModal(true);
  },[isChangingPass]);

  if(showNewPassPage){
    return <NewPassword/>
  }

  return <div style={{ textAlign: 'center' }}>
    <form>
      <Box
        width="100%"
        display="flex"
        flexWrap="wrap"
        flexDirection="column"
        boxSizing="border-box"
        justifyContent="center"
      >
        <Text className={classes.title} >Cambiar la contraseña</Text>
        <PasswordTextField
          error={isErrorPass}
          helperText='Tu contraseña tiene que tener al menos: 8 caracteres, 1 mayúscula, 1 minúscula y 1 número.'
          className={classes.textInput}
          data-test-id="input-login-password"
          label='Nueva contraseña'
          value={newPass}
          onChange={ e => {
            setNewPass(e.target.value.trim());
            setIsErrorPass(!isValidPass(e.target.value.trim()));
            repeatPass.length > 0 && setIsErrorRepeatPass(e.target.value.trim() !== repeatPass);
            isErrorPass && setIsErrorPass(!isValidPass(e.target.value.trim()));
            if (e.target.value.trim() === '') setShowRepeatMessage(false);
          }}
          variant="outlined"
          onBlur={() => {
            newPass.length > 0 && setIsErrorPass(!isValidPass(newPass));
            if (repeatPass.length > 0)
              setIsErrorRepeatPass(newPass !== repeatPass);
            else
              setIsErrorRepeatPass(false);
          }}
          InputProps={{
            style: { paddingLeft: '.8rem' },
          }}
        />
        <PasswordTextField
          error={isErrorRepeatPass || showRepeatMessage}
          helperText={isErrorRepeatPass
            ? 'Las contraseñas no coinciden. Intentá de nuevo.'
            : showRepeatMessage
              ?  'Por favor, repetí tu contraseña.'
              : ''
          }
          className={ clsx(classes.textInput, classes.inputRepeatPass)}
          data-test-id="input-login-password"
          label='Repetir la contraseña'
          value={repeatPass}
          onChange={ e => {
            setRepeatPass(e.target.value.trim());
            setIsErrorRepeatPass(newPass !== e.target.value.trim());
            isErrorRepeatPass && setIsErrorRepeatPass(newPass !== e.target.value.trim());
          }}
          variant="outlined"
          onBlur={() => {
            if (repeatPass.length > 0)
              setIsErrorRepeatPass(newPass !== repeatPass);
            else
              setIsErrorRepeatPass(false);
            setShowRepeatMessage(false);
          }}
          InputProps={{
            style: { paddingLeft: '.8rem' },
          }}
        />
        <Button
          primary
          className={classes.button}
          type="submit"
          fullWidth
          disabled={isChangingPass}
          onClick={ e => {
            e.preventDefault();
            repeatPass.length === 0 && newPass.length > 0 && setShowRepeatMessage(true);
            if ( newPass.length === 0 ) {
              setIsErrorPass(true);
              return;
            }
            if (repeatPass.length === 0) return;
            if (!isErrorPass && !isErrorRepeatPass) {
              updatePassword({ variables: { email, code, password: newPass } });
            }
          }}
        >
          Confirmar
        </Button>
      </Box>
    </form>
    { showErrorModal &&
      <LoadingModal
        open={true}
        onClose={() => setShowErrorModal(false)}
        handleCloseModalClick={() => setShowErrorModal(false)}
        updatedSuccess={false}
        updating={false}
        customVisibility={true}
        errorMessage='¡Algo pasó!'
        errorLeyendSecondary='Los datos no llegaron pero podés volver a intentarlo.'
        btnTxt='Volver a intentar'
        sectionLeyendSecondary={classes.sectionLeyendSecondary}
      />
    }
  </div>;
};

export default ChangePasswordForm;
