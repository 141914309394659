import { useMutation, useQuery } from '@apollo/react-hooks';
import React, { useState } from 'react';
import { SEND_PASSWORD_RECOVERY_CODE } from '../../mutations/sendPasswordRecoveryCode';
import { GET_VALIDATION_CODE } from '../../queries/getValidationCode';
import { FIELDS_INITIAL_VALUE, MESSAGE_EXPIRED_CODE, MESSAGE_INVALID_CODE } from './const';
import { PasswordActivationForm } from './PasswordActivationForm';

const VALIDATION_TITLE = '¡Genial!';
const VALIDATION_SUBTITLE = 'Para cambiar tu contraseña, ingresá el código que te enviamos a:';

export const PasswordActivationContainer = ({ email = '', onAcceptCode }) => {

  const [errorMessage, setErrorMessage] = useState('');
  const [fields, setFields] = useState(FIELDS_INITIAL_VALUE);
  const [hasFocus, setHasFocus ] = useState(true);
  const [code, setCode ] = useState('');


  const { loading: loadingValidationCode, refetch } = useQuery(GET_VALIDATION_CODE, {
    onCompleted: async (data) => {
      const expiredCode = data.getValidationCode.expired;
      const invalidCode = data.getValidationCode.invalid;
      const successResponse = data.getValidationCode.success;
      // Sí no es la primera carga (error) o la respuesta es exitosa
      if (expiredCode !== null || successResponse) {
        if (invalidCode) {
          setHasFocus(prevfocus => true);
          setErrorMessage(MESSAGE_INVALID_CODE);
          setFields(FIELDS_INITIAL_VALUE);
          return;
        }
        if (expiredCode) {
          setHasFocus(false);
          setErrorMessage(MESSAGE_EXPIRED_CODE);
          return;
        }
        successResponse && setErrorMessage('');
        onAcceptCode({ code, show: true });
      }
    },
    notifyOnNetworkStatusChange: true,
  });

  const [sendPasswordRecoveryCode, { loading: resendCodeLoading }] =
  useMutation(SEND_PASSWORD_RECOVERY_CODE, {
    onCompleted: () => {
      setHasFocus(true);
      setErrorMessage('');
      setFields(FIELDS_INITIAL_VALUE);
    }
  });

  const handleOnSubmit = (newFields) => {
    const newCode = Object.values(newFields).join('');
    setCode(newCode);
    refetch({ user: email, code: newCode });
  };

  const handleResendCode = () => {
    setHasFocus(false);
    sendPasswordRecoveryCode({
      variables: { email },
    });
  };
  return (
    <PasswordActivationForm
      handleOnSubmit={handleOnSubmit}
      fields={fields}
      setFields={setFields}
      title = {VALIDATION_TITLE}
      subTitle = {VALIDATION_SUBTITLE}
      errorMessage={errorMessage}
      email={email}
      loading={loadingValidationCode || resendCodeLoading}
      handleResendCode={handleResendCode}
      hasFocus={ hasFocus}
      setHasFocus={setHasFocus}
    />
  );
};
