import { Box, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import Button from '../common/button.component';
import Text from '../common/text.component';
import TextField from '../common/TextField.component';

const TEXT_BUTTON = 'Recuperar contraseña';
const EMAIL_LABEL = 'E-mail';
const TITLE_RECOVERY = 'Recuperar mi contraseña';
const SUBTITLE_RECOVERY = 'Escribí tu e-mail y te enviaremos un código para verificar tu identidad.';

const useStyle = makeStyles((theme) => ({
  button: {
    marginTop: '3.5rem',
    fontSize:theme.typography.size.small,
  },
  title:{
    marginBottom:'0.5rem',
  },
  container:{
    width:'100%',
    display:'flex',
    flexDirection:'column',
  },
  textInput: {
    width: '100%',
    marginTop: '4.875rem',
    '& input': {
      padding: '.9688rem 1.5rem',
    },
    '& label': {
      paddingLeft: '1rem',
      color: `${theme.palette.grayScale.g400} !important`,
      transform: 'translate(.438rem, 1rem) scale(1)',
    },
    '& fieldset': {
      borderColor: theme.palette.grayScale.g300,
    },
    '& p':{
      [theme.breakpoints.down('sm')]: {
        marginRight:0
      },
    }
  },
}));

export const FormRecuperarContrasenia = ({
  validateFields = () => {},
  recoverPassword = ()=> {},
  fields,
  setFields,
  emailValidationLoading
}) => {

  const classes = useStyle();

  const [formSubmited, setFormSubmited] = useState(false);
  const [errorMessages, setErrorMessages] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmited(true);
    const isValid = validateForm(fields);
    recoverPassword(isValid);
  };

  const validateForm = (newFields) => {
    const { valid, errors } = validateFields(newFields);
    setErrorMessages(()=>errors);
    return valid;
  };

  const handleTextFieldChange = (key, value) => {
    const newFields = { ...fields };
    newFields[key] = value;
    formSubmited && validateForm(newFields);
    setFields(newFields);
  };

  return (

    <>
      <Text className={classes.title} large semibolder>
        {TITLE_RECOVERY}
      </Text>
      <Text little g600 center>
        {SUBTITLE_RECOVERY}
      </Text>
      <Box component={'form'} className={classes.container}>
        <TextField
          error={errorMessages && !errorMessages.email.valid}
          helperText={errorMessages && errorMessages.email.error}
          className={classes.textInput}
          data-test-id="input-recover-email"
          label={EMAIL_LABEL}
          value={fields.email}
          onChange={(evt) => handleTextFieldChange('email' ,evt.target.value)}
          variant="outlined"
        />
        <Button
          primary
          onClick={handleSubmit}
          data-test-id="recuperar-contrañena-btn"
          className={classes.button}
          type="submit"
          disabled={emailValidationLoading}
        >
          {TEXT_BUTTON}
        </Button>
      </Box>
    </>

  );
};
