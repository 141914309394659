import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { FormRecuperarContrasenia } from './FormRecuperarContrasenia';
import { validateEmail } from '../../utils/validators.utils';

const ErrorMessage = ({ text }) => (
  <Box component={'span'} ml="10px" fontSize="11px">
    {text}
  </Box>
);

const RecuperarContrasenia = ({
  fields,
  setFields,
  checkIfEmailExists,
  showErrorView,
  setShowValidation,
  sendPasswordRecoveryCode,
}) => {
  const [emailValidationLoading, setEmailValidationLoading] = useState(false);

  const validateFields = (newFields) => {

    const isValidEmail = validateEmail(
      newFields.email,
      <ErrorMessage text="El e-mail no es válido. Intentá de nuevo." />,
      <ErrorMessage text="Escribí tu e-mail para recuperar tu contraseña." />
    );

    const valid = isValidEmail.valid;

    const errors = {
      email: isValidEmail,
    };

    return { valid, errors };
  };

  const recoverPassword = async (isValid) => {
    if(!isValid) return;

    setEmailValidationLoading(() => true);
    const emailValidationResponse = await checkIfEmailExists();
    setEmailValidationLoading(() => false);

    if(!emailValidationResponse?.data?.verifyEmail?.success) return showErrorView();

    sendPasswordRecoveryCode({ variables: { email: fields.email } });
    setShowValidation(true);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      maxWidth="25rem">
      <FormRecuperarContrasenia
        validateFields={validateFields}
        recoverPassword={recoverPassword}
        fields={fields}
        setFields={setFields}
        emailValidationLoading={emailValidationLoading}
      />
    </Box>
  );
};

export default RecuperarContrasenia;
