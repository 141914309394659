import React from 'react';
import Box from '@material-ui/core/Box';
import Text from '../common/text.component';
import Button from '../common/button/Button.component';
import { makeStyles } from '@material-ui/core/styles';

const MAIN_TITLE = 'Algo pasó...';
const DESCRIPTION_TEXT = 'No encontramos una cuenta registrada con este e-mail:';
const RETRY_TEXT = 'Podés intentar de nuevo y cambiar el e-mail.';
const RETRY_ACTION_LABEL = 'Cambiar el e-mail';
const CONTACT_TEXT = 'Si el e-mail es con el que te registraste, por favor comunicate con nosotros.';
const CONTACT_ACTION_LABEL = 'Comunicarme';

const useStyles = makeStyles((theme) => ({
  'main-container': {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    alignItems: 'center',

    '& > span': {
      display: 'block'
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '25rem',
      padding: 0,
    }
  },
  'margin-bottom-0-5': {
    marginBottom: '0.5rem',
  },
  'margin-bottom-md-2': {
    marginBottom: '1rem',

    [theme.breakpoints.up('sm')]: {
      marginBottom: '2rem'
    }
  },
  'margin-bottom-1': {
    marginBottom: '1rem'
  },
  'retry-text': {
    maxWidth: '28ch'
  },
  'margin-bottom-5-5': {
    marginBottom: '5.5rem'
  },
  'contact-text': {
    maxWidth: '32ch'
  },
  'email': {
    wordBreak: 'break-word'
  },
  'description': {
    fontSize: '1rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '0.875rem'
    }
  },
  'full-width': {
    width: '100%'
  }
}));

export default function SomethingHappened({ email, hideErrorView }) {
  const classes = useStyles();

  return (
    <Box className={classes['main-container']}>
      <Text
        large
        semibolder
        className={classes['margin-bottom-0-5']}>
        {MAIN_TITLE}
      </Text>
      <Text
        little
        g600
        center
        className={`${classes['margin-bottom-md-2']} ${classes['description']}`}>
        {DESCRIPTION_TEXT}
      </Text>
      <Text
        small
        semibolder
        center
        className={`${classes['margin-bottom-md-2']} ${classes['email']}`}>
        {email}
      </Text>
      <Text
        small
        g600
        center
        className={`${classes['retry-text']} ${classes['margin-bottom-1']}`}>
        {RETRY_TEXT}
      </Text>
      <Button
        primary
        classNameProps={`${classes['margin-bottom-5-5']} ${classes['full-width']}`}
        data-test-id="try-another-email-button"
        onClick={hideErrorView}
      >
        {RETRY_ACTION_LABEL}
      </Button>
      <Text
        small
        g600
        center
        className={`${classes['contact-text']} ${classes['margin-bottom-1']}`}>
        {CONTACT_TEXT}
      </Text>
      <a href={`mailto:${process.env.GATSBY_CARMUV_ADMIN_EMAIL}`} className={classes['full-width']}>
        <Button type='secondary' classNameProps={classes['full-width']}>{CONTACT_ACTION_LABEL}</Button>
      </a>
    </Box>
  );
}
